<template>
  <b-container fluid>
    <streaming-section />
  </b-container>
</template>

<script>
import { BContainer } from 'bootstrap-vue';
import StreamingSection from '@core/components/streaming/StreamingSection.vue';

export default {
  directives: {},
  components: {
    BContainer,
    StreamingSection,
  },
};
</script>
