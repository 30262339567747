import { toAbsoluteURL } from '@core/utils/url-utils';
import { EmailRegex } from '@copernicsw/community-common';

export const validatorPositive = (value) => {
  if (value >= 0) {
    return true;
  }
  return false;
};

export const validatorPassword = (password) => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/;
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password);
  return validPassword;
};

export const validatorCreditCard = (creditnum) => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/;
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum);
  return validCreditCard;
};

export const validatorUrlValidator = (val) => {
  if (val === undefined || val === null || val.length === 0) {
    return true;
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
  /* eslint-enable no-useless-escape */
  return re.test(val);
};

export const validatorUrlYoutube = (val) => {
  const isValidURL = validatorUrlValidator(val);

  if (!isValidURL) {
    return false;
  }

  const url = new URL(toAbsoluteURL(val));
  let isValid = false;
  if (['youtube.com', 'www.youtube.com'].includes(url.hostname)) {
    isValid = url.pathname === '/watch' && !!url.searchParams.get('v');
  } else if (url.hostname === 'youtu.be') {
    isValid = url.pathname !== '/';
  }

  return isValid;
};

export const validatorEmailValidator = (val) => {

  if (val === undefined || val === null || val.length === 0) {
    return true;
  }
  // console.log('validatorEmailValidator email', val);

  /* eslint-disable no-useless-escape */
  const regex = EmailRegex;
  /* eslint-enable no-useless-escape */
  const isValid = regex.test(val);
  // console.log('validatorEmailValidator isValid', isValid);

  return isValid;
};

export const userNameValidator = (val) => {
  if (val === undefined || val === null || val.length === 0) {
    return true;
  }
  /* eslint-disable no-useless-escape */
  const re = /[a-zA-Z0-9-_]/g;

  /* eslint-enable no-useless-escape */
  return re.test(val);
};

export const validatorPhoneValidator = (val) => {
  if (val === undefined || val === null || val.length === 0) {
    return true;
  }
  /* eslint-disable no-useless-escape */
  const re = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/g;
  /* eslint-enable no-useless-escape */
  return re.test(val);
};

export const validatorPrefixValidator = (val) => {
  if (val === undefined || val === null || val.length === 0) {
    return true;
  }
  /* eslint-disable no-useless-escape */
  const re = /^\+\S[\d\s]{1,5}$/g;
  /* eslint-enable no-useless-escape */
  return re.test(val);
};

export const validatorNifValidator = (val) => {
  if (val === undefined || val === null || val.length === 0) {
    return true;
  }
  /* eslint-disable no-useless-escape */
  const re = /([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])/g;
  /* eslint-enable no-useless-escape */
  return re.test(val);
};

export const dateValidator = (val) => {
  const re = /^(19[0-9]{2}|2[0-9]{3})-(0[1-9]|1[012])-([123]0|[012][1-9]|31)$/;
  /* eslint-enable no-useless-escape */
  return re.test(val);
};

export const validatorDniValidator = (val) => {
  if (val === undefined || val === null || val.length === 0) {
    return true;
  }
  /* eslint-disable no-useless-escape */
  const re = /((([X-Z])|([LM])){1}([-]?)((\d){7})([-]?)([A-Z]{1}))|((\d{8})([-]?)([A-Z]))/g;
  /* eslint-enable no-useless-escape */
  return re.test(val);
};
export const validatorWithoutSpecialCharacters = (val) => {
  if (val === undefined || val === null || val.length === 0) {
    return true;
  }
  /* eslint-disable no-useless-escape */
  const re = /^[A-Za-z0-9-]+$/;
  /* eslint-enable no-useless-escape */
  return re.test(val);
};
