<template>
  <b-card class="p-0 streaming-body" style="height: calc(100vh - 150px)">
    <feather-icon
      v-if="isStaff"
      icon="Edit2Icon"
      size="20"
      class="text-primary edit-icon"
      role="button"
      @click="isModalOpened = true"
    />
    <v-select
      v-if="items != null && items.length > 1"
      name="classifier-filter"
      :clearable="false"
      label="name"
      v-model="name"
      :reduce="(a) => a"
      :options="items"
      class="mb-2"
      @input="setSelected"
    />
    <b-embed
      v-if="selectedOption && !isHTML"
      ref="iframe"
      type="iframe"
      style="max-height: calc(100vh - 250px)"
      :src="selectedOption"
      v-bind="streamingSettings"
    />
    <div
      id="frameForExternals"
      style="width: 100%; height: 80%; position: relative; display: none"
    ></div>
    <div v-if="!selectedOption" class="streaming">
      <img :src="placeholderStreaming" class="p-3 streaming-pleaceholder" />
      <p class="text-center text-primary font-weight-bold">
        {{ $t("notStarter.message") }}
      </p>
    </div>
    <b-modal
      v-model="isModalOpened"
      :ok-title="$t('form.actions.save')"
      :title="$t('streaming.edit')" 
      ok-only
      size="lg"
      :ok-disabled="isDisabled"
      @hidden="reset()"
      @ok="createStreaming()"
    >
      <!-- <template #modal-header>
        <language-selector-header :title="items && items.length > 0 ? $t('streaming.edit') : $t('streaming.create')" @closeModal="closeModal" @selectLanguage="(language)=>selectedLanguage = language"/>
      </template> -->
      <b-row v-if="isFirstLinkSectionVisible">
        <b-col cols="12" lg="6">
          <label for="streaming-name" class="font-weight-bold mt-75"> {{ $t('streaming.label') }}*</label>
          <b-form-input
            id="streaming-name"
            v-model="form.name"
            :state="isNameValid"
            @input="validatorName(form.name, isNewValid = true)"
            type="text"
            :placeholder="$t('form.type-placeholder')"
          />
        </b-col>
        <b-col cols="12" lg="6">
          <b-form-group class="flex-grow-1">
            <label for="streaming-src" class="font-weight-bold mt-75"> {{ $t('streaming.link') }}*</label>
            <b-form-input
              id="streaming-src"
              v-model="form.src"
              type="text"
              :placeholder="$t('streaming.example')"
              :state="isFormValid"
              @input="validator(form.src, isNewValid = true)"
            />
            <small v-if="isFormValid === false" class="text-danger mt-25">
              {{ $t('streaming.error') }}
            </small>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- All links -->
      <div v-if="links.length > 0">
        <b-row v-for="(link, index) in links" :key="index">
          <b-col cols="12" lg="6">
            <label :for="`streaming-name${index}`" class="font-weight-bold mt-75"> {{ $t('streaming.label') }}*</label>
            <b-form-input
              :id="`streaming-name${index}`"
              v-model="link.name"
              type="text"
              :state="isNamesValid[index]"
              @input="validatorName(link.name, isNewValid = false, index)"
              :placeholder="$t('form.type-placeholder')"
            />
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-group class="flex-grow-1">
              <label :for="`streaming-src${index}`" class="font-weight-bold mt-75"> {{ $t('streaming.link') }}*</label>
              <div class="d-flex align-items-center">
                <b-form-input
                  :id="`streaming-name${index}`"
                  v-model="link.src"
                  type="text"
                  :placeholder="$t('streaming.example')"
                  :state="isValid[index]"
                  @input="validator(link.src, isNewValid = false, index)"
                />
                <feather-icon icon="Trash2Icon" class="pointer text-primary ml-50" @click="removeItem(index)"/>
              </div>
              <small v-if="isValid[index] === false" class="text-danger mt-25">
                {{ $t('streaming.error') }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-button variant="link" class="p-0 pointer font-weight-bold" @click="addLink()">
        {{ $t('streaming.add') }}
      </b-button>
    </b-modal>
    <unshare-modal
      v-model="isUnshareModalVisible"
      :model-type="'streaming'"
      @remove-item="handleRemoveItem"
    />
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import StreamingPlaceholder from "@/assets/images/placeholders/light/streaming.svg";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import { validatorUrlValidator } from '@/@core/utils/validations/validators';
import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue'
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import UnshareModal from '@/@core/components/modal/UnshareModal.vue';

const IFrameAttributes = Object.freeze([
  "allow",
  "allowfullscreen",
  "allowpaymentrequest",
  "csp",
  "height",
  "loading",
  "name",
  "referrerpolicy",
  "sandbox",
  "src",
  "srcdoc",
  "width",
  // deprecated attributes:
  "align",
  "frameborder",
  "longdesc",
  "marginheight",
  "marginwidth",
  "scrolling",
]);

export default {
  name: 'StreamingSection',
  components: { vSelect, LanguageSelectorHeader, UnshareModal },
  mixins: [WidgetLayoutMixin, ToastNotificationsMixin],
  props: {
    large: Boolean,
  },
  data() {
    return {
      form: {
        src: '',
        name: '',
      },
      links: [],
      selectedLanguage: '',
      selectedOption: null,
      isHTML: false,
      name: null,
      windowWidth: window.innerWidth,
      placeholderStreaming: StreamingPlaceholder,
      isValid: [],
      isFormValid: null,
      isNameValid: null,
      isNamesValid: [],
      isFirstLinkSectionVisible: false,
      isRemoving: false,
      isUnshareModalVisible: false,
      currentStreaming: '',
      isModalOpened: false,

    };
  },
  computed: {
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    isDisabled() {
      return this.isNameValid === false || this.isFormValid === false;
    },
    items() {
      let item = this.$store.getters.iframes.data;
      if (item != null) {
        for (let row in item) {
          item[row].name = this.translateTextOption(item[row].name);
        }
      }
      return item || [];
    },
    hasMoreStreamings() {
      return this.items && this.items.length > 1;
    },
    streamingSettings() {
      const streamingSelected = this.items.find(
        (item) => item.src === this.selectedOption
      );
      const settings = Object.entries(streamingSelected?.settings || {}).reduce(
        (filteredSettings, [key, value]) => {
          if (IFrameAttributes.includes(key)) {
            return {
              ...filteredSettings,
              [key]: value,
            };
          }

          return filteredSettings;
        },
        {}
      );

      const defaultSettings = { allow: "fullscreen" };
      return { ...defaultSettings, ...settings };
    },
  },
  watch: {
    items(value) {
      this.selectedOption = value[0]?.src || null;
      this.links = value.slice() || [];
      if (this.links.length === 0) {
        this.isModalOpened = false;
      }
    },
  },
  async created() {
    await this.getInitialData();
    this.selectedOption = this.items[0]
    this.setSelected(this.items[0])
    document.addEventListener("fullscreenchange", this.handleFullscreenChange);
  },
  beforeDestroy() {
    document.removeEventListener(
      "fullscreenchange",
      this.handleFullscreenChange
    );
  },
  methods: {
    async createStreaming() {
      if (this.isFirstLinkSectionVisible === true && this.form.src != '' && this.form.name != '') {
        this.form.isNew = true;
        this.links.push(this.form);
      }
      const newLinks = this.links.filter(item=>item.isNew);
      for (const link of newLinks) {
        try {
          await this.$store.dispatch("createItem", {
            noSet: true,
            item: {
              itemType: 'iframes',
              requestConfig: {
                name: {es: link.name },
                src: link.src,
              },
            },
          });
        } catch {
          this.notifyError(this.$t('streaming.messages.error-edit'));
        }
      }
      let oldLinks = [];
      oldLinks = this.links.filter(item=>!item.isNew);
            if (oldLinks.length>0) {
      for (const link of oldLinks) {
        try {
          await this.$store.dispatch("editItem", {
            noSet: true,
            item: {
              itemType: 'iframes',
              requestConfig: {
                name: {es: link.name },
                src: link.src,
                key: link.key,
              },
            },
          });
        } catch {
          this.notifyError(this.$t('streaming.messages.error-edit'));
        }
      }
    }
      this.notifySuccess(this.$t('streaming.messages.success-edit'));
      await this.getInitialData();
    },
    validator(value, isNewValid, index) {
      if (isNewValid) {
        this.isFormValid = validatorUrlValidator(value) && value != '';
        this.isNameValid = this.form.name !== ''
      }
      if (value != '') {
        this.isValid[index] = validatorUrlValidator(value) && value != '';
      } else {
        this.isValid[index] = false
      }
    },
    validatorName(value, isNewValid, index) {
      if (isNewValid) {
        this.isNameValid = value !== ''
        this.validator(this.form.src, isNewValid)
      }
      if (value != '') {
        this.isNamesValid[index] = value !== '';
      } else {
        this.isNamesValid[index] = false
      }
    },
    reset() {
      this.form = {
        src: '',
        name: '',
      };
      this.isFormValid = null;
      this.isNameValid = null;
      if (this.items.length > 0) {
        this.isFirstLinkSectionVisible = false;
        this.isNamesValid = [];
        this.isValid = [];
        this.getInitialData();
      } else {
        this.isFirstLinkSectionVisible = true;
      }
    },
    addLink() {
      if (this.isFirstLinkSectionVisible === true && this.form.src != '' && this.form.name != '' && this.isFormValid != false &&
      this.isNameValid != false) {
        this.form.isNew = true;
        this.links.push(this.form);
        this.form = {
          src: '',
          name: '',
        };
        this.isFormValid = null;
        this.isNameValid = null;
      } else {
        this.isFirstLinkSectionVisible = true;
      }
    },
    setSelected(item) {
       const deleteFrame = document.getElementById("scrp");
        if(deleteFrame != null){
          deleteFrame.remove();
        }
        const main = document.getElementById("frameForExternals")
        main.innerHTML = ""
        const frame = document.createElement("div");
        frame.setAttribute("id", "svp_playeraq2hz7x0kh4o");
        main.appendChild(frame);
      if (item?.html != null) {
        this.isHTML = true;
        this.selectedOption = item.html;
        const frame = document.createElement("script");
        frame.setAttribute("id", "scrp");
        frame.innerText = item.html;
        document.body.appendChild(frame);
        document.getElementById("frameForExternals").style.display =
          "block";
        document.getElementById("svp_playeraq2hz7x0kh4o").style.display =
          "block";
        document.getElementById("svp_playeraq2hz7x0kh4o").style.paddingTop =
          "0px !important";
      } else {
        document.getElementById("svp_playeraq2hz7x0kh4o").style.display =
          "none";
          document.getElementById("frameForExternals").style.display =
          "none";
        this.isHTML = false;
        this.selectedOption = item?.src;
        this.name = item?.name;
      }
    },
    async getInitialData() {
      await this.$store.dispatch("getItemsNode", {
        itemType: "iframes",
        page: 1,
        perPage: 16,
      });
      if (this.items.length > 0) {
        this.selectedOption = this.items[0].src;
        this.name = this.items[0].name;
        this.isFirstLinkSectionVisible = false;
        this.links = this.items.slice();
      } else {
        this.isFirstLinkSectionVisible = true;
      }
    },
    handleFullscreenChange(event) {
      if (
        event.srcElement ===
          this.$refs.iframe.getElementsByTagName("iframe")[0] &&
        !document.fullscreenElement // document.fullscreenElement is only present when in fullscreen mode.
      ) {
        this.$el.scrollIntoView({ block: "center" });
      }
    },
    translateTextOption(name) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        name
      );
    },
    closeModal() {
      this.$bvModal.hide('edit');
    },
    removeItem(index) {
      this.isUnshareModalVisible = true;
      this.currentStreaming = this.links[index];
    },
    async handleRemoveItem(executeDelete) {
      if (executeDelete === true) {
        try {
          await this.$store.dispatch('deleteItem', {
            item: {
              storedKey: this.typeKey !== null ? this.typeKey : this.communitySlug,
              itemType: 'Iframes',
              requestConfig: {
                key: this.currentStreaming.key,
              },
            },
            noSet: true,
          });

          this.notifySuccess(this.$t('streaming.messages.success-delete'));
          await this.getInitialData();
        } catch {
          this.notifyError(this.$t('streaming.messages.error-delete'));
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include"; // Bootstrap includes

@include media-breakpoint-down(md) {
  .buttons-group {
    float: left !important;
    margin-bottom: 10px;
  }
}

.streaming-pleaceholder {
  max-height: 200px;
  margin: 0 auto;
  display: block;
}
#svp_playeraq2hz7x0kh4o {
  width: 720px; height: 405px; position: relative; display: none;
}
.edit-icon {
  position: absolute;
  right: 0px;
  margin-top: -4.5rem !important;
  cursor: pointer;
}
</style>
